<template>
  <div id="chat" class="chat">
    <div v-if="loading" class="text-center">
      <v-progress-circular :size="60" color="primary" indeterminate />
    </div>
    <div v-else>
      <v-container grid-list-xl fluid>
        <v-layout wrap>
          <v-flex lg12 md12>
            <div id="chat-container" style="width: 100%; height: 500px">
              <i>{{ this.$t('mensajeria.loading_chat') }}</i>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </div>
</template>

<script>
import Talk from 'talkjs';

export default {
  components: {},
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    disabledButton: false,
    snackbar: false,
    timeout: 5000,
    mode: '',
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
      tab: 2,
    },
    talkSession: null,
    chat_noleidos: 0,
  }),
  created() {
    if (this.authUser.signature !== null && this.authUser.signature !== '' && this.authUser.chatSession !== null) {
      this.loading = false;
      Talk.ready.then(() => {
        const me = new Talk.User({
          id: 'PROF' + this.authUser.id,
          name: this.authUser.fullName,
          email: this.authUser.email,
          photoUrl: this.authUser.img,
          role: 'buyer',
          welcomeMessage: 'Hola, ¿en que puedo ayudarte?',
          locale: 'es-ES',
        });
        this.talkSession = new Talk.Session({
          appId: 'tC7ozAH1',
          me: me,
          signature: this.authUser.signature,
        });
        const inbox = this.talkSession.createInbox();
        inbox.mount(document.getElementById('chat-container'));
      });
    } else {
      this.$router.push('/dashboard');
    }
  },

  methods: {},
  // beforeRouteLeave (to, from, next) {
  //   // verificar si no le ha contestado a algún paciente
  // }
};
</script>

<style scoped lang="css"></style>
